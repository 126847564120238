#image-track {
  display: flex;
  gap: 4vmin;
  position: relative;
  left: 50%;
  bottom: 0;
  margin-top: 100px;
  transform: translate(0%, -50%);
  user-select: none;
  width: 100%;
}
  
  #image-track > .image {
    width: 40vmin;
    height: 56vmin;
    object-fit: cover;
    object-position: 100% center;
  }