#loadingPanelON, #loadingPanelOFF {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;    
}

#backgroundList {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background-color: white;
    top: 0;
    left: 0;
    padding: 25%;
    opacity: 0.5;
}

#img1 {
    position: fixed;
    z-index: 10001;
    max-width: 128px;
    min-width: 64px;
}

#img2 {
    position: fixed;
    z-index: 10000;
    width: 50%;
    min-width: 300px;
    max-width: 700px;
}

#loadingPanelON {    
    animation: whiteIn 1s forwards;
}

@keyframes whiteIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

#loadingPanelOFF {    
    animation: whiteOUT 1s forwards;
}

@keyframes whiteOUT {
    100% {
        opacity: 0;
        z-index: 0;
    }
    0% {
        opacity: 1;
    }
}